import React from "react";
import logo from "../assets/newlogo.png";
import { Link, useLocation } from "react-router-dom";
import { sidebarItems } from "../assets/helpers";
import xIcon from "../assets/svgs/sidebar/xIcon.svg";
import share from "../assets/svgs/sidebar/share.svg";

const Sidebar = ({ setIsSidebarOpen }) => {
  const { pathname } = useLocation();

  return (
    <div className="sidebar sidebar-bg h-full overflow-auto p-12 relative">
      <div
        className="lg:hidden block my-auto absolute right-4 top-4 transform border p-1 rounded-md bg-dark"
        onClick={() => setIsSidebarOpen(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path fill="none" d="M0 0h24v24H0V0z" />
          <path
            fill="white"
            d="M4 6h16c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1zm0 6h16c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1zm0 6h16c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1z"
            y="2"
          />
        </svg>
      </div>
      <div className="w-[108px] h-[100px] mx-auto mt-8 mb-20">
        <img src={logo} alt="icon" />
      </div>
      <div className="max-w-[200px] w-full mx-auto mb-20">
        <ul className="flex flex-col gap-3">
          {sidebarItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              onClick={() => setIsSidebarOpen(false)}
              className={`w-[200px] h-[58px] flex justify-center items-center ${
                pathname === item.path
                  ? "border-image"
                  : "bg-dark rounded-[10px]"
              }`}
            >
              <div className="border-img-child w-[196px] h-[54px] flex justify-start px-4 items-center gap-2">
                <div className="w-[18px] h-[18px]">
                  <img src={item.icon} alt="icon" />
                </div>
                <span>{item.label}</span>
                {item?.small && (
                  <p className="text-sm bg-light-gray rounded-[10px] w-16 h-6 text-black font-semibold flex justify-center items-center">
                    {item.small}
                  </p>
                )}
              </div>
            </Link>
          ))}
        </ul>
      </div>
      <div className="flex justify-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => {
            window?.open("https://x.com/VirtuCloud", "_blank");
          }}
        >
          <img src={xIcon} alt="icon" />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            window?.open("https://t.me/VirtuCloud", "_blank");
          }}
        >
          <img src={share} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
