import dashboard from "./svgs/sidebar/dashboard.svg";
import gpu from "./svgs/sidebar/gpu.svg";
import nodes from "./svgs/sidebar/nodes.svg";
import miners from "./svgs/sidebar/miners.svg";
import marketplace from "./svgs/sidebar/marketplace.svg";
import validator from "./svgs/sidebar/validator.svg";
import treasury from "./svgs/sidebar/treasury.svg";
import billing from "./svgs/sidebar/billing.svg";

export const sidebarItems = [
  {
    path: "/",
    icon: dashboard,
    label: "Dashboard",
  },
  {
    path: "/gpu-rental",
    icon: gpu,
    label: "GPU Rental",
  },
  {
    path: "/nodes",
    icon: nodes,
    label: "Nodes",
  },
  {
    path: "/miners",
    icon: miners,
    label: "Miners",
    small: "soon",
  },
  {
    path: "/marketplace",
    icon: marketplace,
    label: "Marketplace",
    small: "soon",
  },
  {
    path: "/validator",
    icon: validator,
    label: "Validator",
    small: "soon",
  },
  {
    path: "/treasury",
    icon: treasury,
    label: "Treasury",
    small: "soon",
  },
  {
    path: "/billing",
    icon: billing,
    label: "Billing",
    small: "soon",
  },
];

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);
