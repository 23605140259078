import React from "react";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Dashboard from "./pages/dashboard";
import GPURental from "./pages/GPU-Rental";
import Nodes from "./pages/Nodes";
import Miners from "./pages/Miners";
import Marketplace from "./pages/Marketplace";
import Validator from "./pages/Validator";
import Treasury from "./pages/Treasury";
import Billing from "./pages/Billing";

function App() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/gpu-rental" element={<GPURental />} />
        <Route path="/nodes" element={<Nodes />} />
        <Route path="/miners" element={<Miners />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/validator" element={<Validator />} />
        <Route path="/treasury" element={<Treasury />} />
        <Route path="/billing" element={<Billing />} />
      </Route>
    </Routes>
  );
}

export default App;
