import React from "react";

const SidebarToggle = ({setIsSidebarOpen}) => {
  return (
    <div
      className="lg:hidden block my-auto absolute left-4 top-4 transform p-1 border rounded-md bg-dark z-20"
      onClick={() => setIsSidebarOpen(true)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="20"
        height="20"
      >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path
          fill="white"
          d="M4 6h16c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1zm0 6h16c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1zm0 6h16c.55 0 1 .45 1 1s-.45 1-1 1H4c-.55 0-1-.45-1-1s.45-1 1-1z"
          y="2"
        />
      </svg>
    </div>
  );
};

export default SidebarToggle;
