import React from "react";
import { useCancelGPU } from "../../utils/gpu.api";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

function CancelModal({ isOpen, onClose, gpu }) {
  const queryClient = useQueryClient();

  const cancelGPU = useCancelGPU();
  const { isConnected, address } = useAccount();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("isConnected", isConnected, address);
    if (!isConnected) {
      toast.error("Please connect your wallet first");
      return;
    }
    cancelGPU
      ?.mutateAsync(gpu?._id)
      .then((result) => {
        toast.success("GPU cancelled successfully!");
        queryClient.invalidateQueries({ queryKey: ["getGPUs"] });

        onClose();
      })
      .catch((err) => {
        queryClient.invalidateQueries({ queryKey: ["getGPUs", "getVMs"] });
        toast.error(err.message || "Something went wrong!");
      });
  };

  return (
    isOpen && (
      <div
        className="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="text-center">
                    <h3
                      className="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Cancel GPU
                    </h3>
                  </div>
                </div>

                <h6 className="my-3 text-base font-semibold leading-6 text-gray-900">
                  Are you sure you want to cancel this GPU?
                </h6>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="inline-flex w-full justify-center rounded-md bg-[#76BC00] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#76BC00] sm:ml-3 sm:w-auto"
                  disabled={cancelGPU?.isPending}
                >
                  {cancelGPU?.isPending ? "Cancelling..." : "Yes"}
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CancelModal;
