import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { client } from "./api-client";

export const useGetNodes = () =>
  useQuery({
    queryKey: ["getNodes"],
    queryFn: async () => {
      const response = await client(`nodes`);
      return response;
    },
  });

export const useGetPurchasedNodes = () =>
  useQuery({
    queryKey: ["getPurchasedNodes"],
    queryFn: async () => {
      const response = await client(`api-keys`);
      return response;
    },
  });

export const useBuyNode = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: any) => {
      const response = await client(`nodes/${payload}/buy`, {
        method: "PUT",
      });
      if (response) {
        queryClient.invalidateQueries({ queryKey: ["getNodes"] });
        return response;
      } else {
        throw new Error("Unexpected response from the server");
      }
    },
  });
};
