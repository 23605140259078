import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import SidebarToggle from "../components/SidebarToggle";
import { useQuery } from "@tanstack/react-query";
import { useLogout } from "../utils/auth.api";
import { useAccount, useDisconnect } from "wagmi";

const MainLayout = () => {
  const userLogout = useLogout();

  const { data: token } = useQuery({
    queryKey: ["token"],
    queryFn: () => localStorage.getItem("token"),
  });

  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    if (!token || !isConnected) {
      disconnect();
      userLogout.mutateAsync();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <aside
        className={`fixed top-0 left-0 z-40 max-w-[300px] w-full h-screen transition-transform  ${
          !isSidebarOpen ? "-translate-x-full" : "-translate-x-0"
        } lg:translate-x-0`}
      >
        <div className={`h-full relative z-50`}>
          <Sidebar setIsSidebarOpen={setIsSidebarOpen} />
        </div>
      </aside>
      <div className="lg:ml-[300px] h-screen flex flex-col bg-[#0F0A14] overflow-auto relative">
        <SidebarToggle setIsSidebarOpen={setIsSidebarOpen} />
        {/* I want to fix this image on bottom of page */}
        <div className="flex-1 relative">
          <div className="relative z-10 h-full">
            <Outlet />
          </div>
          <div className="h-full w-full absolute z-0 opacity-5 bg-img top-0"></div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
