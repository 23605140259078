import React, { useState } from "react";
import bandVitch from "../../assets/svgs/bandVitch.svg";
import geforce from "../../assets/svgs/geforce-rtx.svg";
import CancelModal from "../GPU-Rental/cancelModal";
import Spinner from "../../components/Spinner";
import {
  useBuyNode,
  useGetNodes,
  useGetPurchasedNodes,
} from "../../utils/nodes.api";
import { toast } from "react-toastify";
import { IconEye, IconEyeClosed } from "@tabler/icons-react";
import { useAccount } from "wagmi";

const Nodes = () => {
  const { data: nodes, isLoading: nodeLoading } = useGetNodes();
  const { data: ownNodes, isLoading: ownNodesLoading } = useGetPurchasedNodes();
  const purchaseNode = useBuyNode();
  const { isConnected } = useAccount();

  const [selectedGPU, setSelectedGPU] = useState(null);
  const [loadingFor, setLoadingFor] = useState(-1);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [activeView, setActiveView] = useState("all");

  return (
    <div className="flex flex-col gap-8 page_padding relative">
      <div className="gpu-hero-background p-8 rounded-[20px] relative">
        <div className="sm:block hidden absolute top-1/2 lg:-right-20 -right-28 transform -translate-y-1/2 z-10">
          <img src={geforce} className="h-auto lg:w-[80%] w-[60%]" alt="icon" />
        </div>
        <div className="sm:block hidden absolute top-12 right-[8rem] w-20 h-4 bg-transparent z-0 geforce_shadow"></div>
        <div className="absolute -top-8 -left-[9rem] w-20 h-20 bg-transparent z-0 gpu_shadow"></div>

        <h2 className="xl:text-[80px] lg:text-[56px] text-[40px] font-semibold">
          Nodes
        </h2>
      </div>
      <div className="bg-[#2C1649] flex gap-3 items-center px-2 py-1 rounded-[10px] max-w-[145px]">
        <div
          className={` ${
            activeView === "all"
              ? "bg-[#C98BFF]  rounded-[10px]"
              : "text-[#CFCFCF82] text-[16px]"
          } cursor-pointer w-[45px] h-[32px] flex items-center justify-center`}
          onClick={() => {
            setSelectedGPU(null);
            setActiveView("all");
          }}
        >
          <p>All</p>
        </div>
        <div
          className={` ${
            activeView === "rented"
              ? "bg-[#C98BFF] rounded-[10px]"
              : "text-[#CFCFCF82] text-[16px] "
          }  w-[75px] h-[32px] flex items-center justify-center cursor-pointer`}
          onClick={() => {
            setSelectedGPU(null);
            setActiveView("rented");
          }}
        >
          <p>Owned</p>
        </div>
      </div>
      {activeView === "all" ? (
        <>
          {nodeLoading ? (
            <div className="flex justify-center items-center h-[300px]">
              <Spinner size="6" />
            </div>
          ) : (
            <div className="overflow-x-auto p-4 rounded-[20px] bg-dark">
              <table className="w-full text-sm text-left table-auto">
                <thead className="text-sm border-b-[#9D9D9D] border-b border-opacity-60">
                  <tr className="font-bold whitespace-nowrap">
                    <th className="pb-4 px-6 text-[#9D9D9D]">Name</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {nodes?.map((item) => (
                    <tr key={item.id} className="cursor-pointer">
                      <td className="px-4 pt-4">
                        <div className="w-[250px] p-2 bg-[#76BC00] rounded-[10px]">
                          <div className="flex justify-between items-center mb-1">
                            <div className="flex gap-2 items-center">
                              <div className="flex items-center">
                                <img src={bandVitch} alt="icon" />
                              </div>
                              <h2 className="text-[16px] font-bold text-black">
                                {item?.name || " "}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="font-semibold px-4 pt-4">
                        ${item?.price || 0}
                      </td>
                      <td className="pt-4">
                        <button
                          disabled={purchaseNode?.isPending}
                          className="font-semibold bg-[#76BC00] py-2 px-3 rounded-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            setLoadingFor(item?._id);
                            if (!isConnected) {
                              toast.error("Please connect your wallet first");
                              return;
                            }
                            purchaseNode
                              .mutateAsync(item?._id)
                              .then((result) => {
                                toast.success("Node purchased successfully");
                              })
                              .catch((err) => {
                                debugger;
                                toast.error(
                                  err?.response?.data?.message ||
                                    err?.message ||
                                    "An error occurred"
                                );
                              });
                          }}
                        >
                          {purchaseNode?.isPending && loadingFor === item?._id
                            ? "Processing..."
                            : "Buy"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <>
          {ownNodesLoading ? (
            <div className="flex justify-center items-center h-[300px]">
              <Spinner size="6" />
            </div>
          ) : !ownNodes?.length ? (
            <div className="flex justify-center">
              <h2 className="text-[#9D9D9D] text-center">No nodes owned yet</h2>
            </div>
          ) : (
            <div className="overflow-x-auto p-4 rounded-[20px] bg-dark">
              <table className="w-full text-sm text-left table-auto">
                <thead className="text-sm border-b-[#9D9D9D] border-b border-opacity-60">
                  <tr className="font-bold whitespace-nowrap">
                    <th className="pb-4 px-6 text-[#9D9D9D]">Name</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">PRICE</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">SECRET KEY</th>
                  </tr>
                </thead>
                <tbody>
                  {ownNodes?.map((item) => (
                    <tr key={item.id} className="cursor-pointer">
                      <td className="px-4 pt-4">
                        <div className="w-[250px] p-2 bg-[#76BC00] rounded-[10px]">
                          <div className="flex justify-between items-center mb-1">
                            <div className="flex gap-2 items-center">
                              <div className="flex items-center">
                                <img src={bandVitch} alt="icon" />
                              </div>
                              <h2 className="text-[16px] font-bold text-black">
                                {item?.linkedNode?.name || " "}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="font-semibold px-4 pt-4">
                        ${item?.linkedNode?.price || 0}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {selectedGPU?._id === item?._id ? (
                          <div className="flex gap-2">
                            {selectedGPU?.secretKey}
                            <IconEyeClosed
                              color="#76BC00"
                              onClick={() => setSelectedGPU(null)}
                              cursor="pointer"
                            />
                          </div>
                        ) : (
                          <IconEye
                            color="#76BC00"
                            cursor="pointer"
                            onClick={() => setSelectedGPU(item)}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}

      <div className="absolute top-1/2 right-[5rem] w-5 h-60 bg-transparent z-[-1] blue_shadow"></div>
      <CancelModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        gpu={selectedGPU}
      />
    </div>
  );
};

export default Nodes;
