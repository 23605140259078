import React, { useState } from "react";
import bandVitch from "../../assets/svgs/bandVitch.svg";
import geforce from "../../assets/svgs/geforce-rtx.svg";
import { useGetGPUs, useGetVMs } from "../../utils/gpu.api";
import Spinner from "../../components/Spinner";
import BuyModal from "./buyModal";
import CancelModal from "./cancelModal";

const GPURental = () => {
  const { data: gpu, isLoading: gpuLoading } = useGetGPUs();
  const { data: vms, isLoading } = useGetVMs();

  const [selectedGPU, setSelectedGPU] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [activeView, setActiveView] = useState("all");

  return (
    <div className="flex flex-col gap-8 page_padding relative">
      <div className="gpu-hero-background p-8 rounded-[20px] relative">
        <div className="sm:block hidden absolute top-1/2 lg:-right-20 -right-28 transform -translate-y-1/2 z-10">
          <img src={geforce} className="h-auto lg:w-[80%] w-[60%]" alt="icon" />
        </div>
        <div className="sm:block hidden absolute top-12 right-[8rem] w-20 h-4 bg-transparent z-0 geforce_shadow"></div>
        <div className="absolute -top-8 -left-[9rem] w-20 h-20 bg-transparent z-0 gpu_shadow"></div>

        <h2 className="xl:text-[80px] lg:text-[56px] text-[40px] font-semibold">
          GPU Rental
        </h2>
      </div>
      <div className="bg-[#2C1649] flex gap-3 items-center px-2 py-1 rounded-[10px] max-w-[145px]">
        <div
          className={` ${
            activeView === "all"
              ? "bg-[#C98BFF]  rounded-[10px]"
              : "text-[#CFCFCF82] text-[16px]"
          } cursor-pointer w-[45px] h-[32px] flex items-center justify-center`}
          onClick={() => setActiveView("all")}
        >
          <p>All</p>
        </div>
        <div
          className={` ${
            activeView === "rented"
              ? "bg-[#C98BFF] rounded-[10px]"
              : "text-[#CFCFCF82] text-[16px] "
          }  w-[75px] h-[32px] flex items-center justify-center cursor-pointer`}
          onClick={() => setActiveView("rented")}
        >
          <p>Rented</p>
        </div>
      </div>
      {activeView === "all" ? (
        <>
          {isLoading ? (
            <div className="flex justify-center items-center h-[300px]">
              <Spinner size="6" />
            </div>
          ) : (
            <div className="overflow-x-auto p-4 rounded-[20px] bg-dark">
              <table className="w-full text-sm text-left table-auto">
                <thead className="text-sm border-b-[#9D9D9D] border-b border-opacity-60">
                  <tr className="font-bold whitespace-nowrap">
                    <th className="pb-4 px-6 text-[#9D9D9D]">Gpu Model</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">Form Factor</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">
                      Max Vcpus Per Gpu
                    </th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">
                      Max MEMORY (GB) PER GPU
                    </th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {vms?.map((item) => (
                    <tr key={item.id}>
                      <td className="px-4 pt-4">
                        <div className="w-[250px] p-2 bg-[#76BC00] rounded-[10px]">
                          <div className="flex justify-between items-center mb-1">
                            <div className="flex gap-2 items-center">
                              <div className="flex items-center">
                                <img src={bandVitch} alt="icon" />
                              </div>
                              <h2 className="text-[16px] font-bold text-black">
                                {item?.machineType || " "}
                              </h2>
                            </div>
                            <div className="bg-black text-[10px] font-medium rounded-[10px] px-2 py-1">
                              General Purpose
                            </div>
                          </div>
                          <p className="text-[10px] text-black">
                            {item?.bootDisk?.sizeGib || 0} GB{" "}
                          </p>
                        </div>
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item.form_factor}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item?.vcpus || 0}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item.memoryGib || 0}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        ${item?.price || 0}/Hour
                      </td>
                      <td className="pt-4">
                        <button
                          className="font-semibold bg-[#76BC00] py-2 px-3 rounded-sm"
                          onClick={() => {
                            setSelectedGPU(item);
                            setIsModalOpen(true);
                          }}
                        >
                          Buy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <>
          {gpuLoading ? (
            <div className="flex justify-center items-center h-[300px]">
              <Spinner size="6" />
            </div>
          ) : (
            <div className="overflow-x-auto p-4 rounded-[20px] bg-dark">
              <table className="w-full text-sm text-left table-auto">
                <thead className="text-sm border-b-[#9D9D9D] border-b border-opacity-60">
                  <tr className="font-bold whitespace-nowrap">
                    <th className="pb-4 px-6 text-[#9D9D9D]">Gpu Model</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">State</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">IP</th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">
                      Max Vcpus Per Gpu
                    </th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">
                      Max MEMORY (GB) PER GPU
                    </th>
                    <th className="pb-4 px-6 text-[#9D9D9D]">PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {gpu?.map((item) => (
                    <tr key={item.id}>
                      <td className="px-4 pt-4">
                        <div className="w-[250px] p-2 bg-[#76BC00] rounded-[10px]">
                          <div className="flex justify-between items-center mb-1">
                            <div className="flex gap-2 items-center">
                              <div className="flex items-center">
                                <img src={bandVitch} alt="icon" />
                              </div>
                              <h2 className="text-[16px] font-bold text-black">
                                {item?.vmApiData?.machineType || " "}
                              </h2>
                            </div>
                            <div className="bg-black text-[10px] font-medium rounded-[10px] px-2 py-1">
                              General Purpose
                            </div>
                          </div>
                          <p className="text-[10px] text-black">
                            {item?.vmApiData?.bootDisk?.sizeGib || 0} GB{" "}
                            {item?.vmApiData?.datacenterId || " "}
                          </p>
                        </div>
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item?.vmApiData?.state}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item?.vmApiData?.externalIpAddress}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item?.vmApiData?.vcpus || 0}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        {item?.vmApiData?.memory || 0}
                      </td>
                      <td className="font-semibold px-4 pt-4">
                        ${item?.perHour || 0}/Hour
                      </td>
                      <td className="pt-4">
                        <button
                          className="font-semibold bg-[#76BC00] py-2 px-3 rounded-sm"
                          onClick={() => {
                            setSelectedGPU(item);
                            setIsCancelModalOpen(true);
                          }}
                        >
                          Cancel
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
      <div className="absolute top-1/2 right-[5rem] w-[-20px] h-60 bg-transparent z-[-1] blue_shadow"></div>
      <BuyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        gpu={selectedGPU}
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        gpu={selectedGPU}
      />
    </div>
  );
};

export default GPURental;
