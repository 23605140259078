import React, { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  useAccount,
  useAccountEffect,
  useDisconnect,
  useSignMessage,
} from "wagmi";
import { useGetLoggedInUser, useLogin, useLogout } from "../../utils/auth.api";
import { toast } from "react-toastify";
import TopupModal from "./topUpModal";
import Logo from "../../assets/newlogo.png";

const Dashboard = () => {
  const userLogin = useLogin();
  const userLogout = useLogout();
  const userGetLoggedInUser = useGetLoggedInUser();

  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const {
    data: signMessageData,
    isLoading,
    signMessage,
    variables,
  } = useSignMessage();

  const { address, isConnected } = useAccount();

  const [isTopupOpen, setIsTopupOpen] = useState(false);

  useAccountEffect({
    onConnect: async ({ address }) => {
      // console.log("Connected with address: ", address);
      signMessage({
        message: "this is signature message for cloudwork",
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (variables?.message && signMessageData) {
        userLogin
          .mutateAsync({ address, signature: signMessageData })
          .then(() => {
            toast.success("Logged in successfully!");
            userGetLoggedInUser.refetch();
          })
          .catch((err) => {
            toast.error(err.message || "Something went wrong!");
            disconnect();
          });
      }
    })();
    // eslint-disable-next-line
  }, [signMessageData, variables?.message]);

  return (
    <div className="flex flex-col gap-8 page_padding relative overflow-hidden h-full">
      <div className="flex justify-between gap-2 flex-wrap items-center">
        <h2 className="md:text-[80px] text-[40px] font-semibold">Dashboard</h2>
        <div className="flex items-center gap-4">
          <p className="text-xl">
            Balance: ${userGetLoggedInUser?.data?.balance || 0}
          </p>
          <div className="dashboard-btn-wrapper">
            <button
              className="text-[16px]"
              disabled={userLogin?.isPending || isLoading}
              onClick={() => {
                if (isConnected) {
                  disconnect();
                  userLogout.mutateAsync();
                } else {
                  open();
                }
              }}
            >
              {isConnected
                ? address.slice(0, 6) + "..." + address.slice(-4)
                : userLogin?.isPending || isLoading
                ? "Connecting..."
                : "Connect"}
            </button>
          </div>
        </div>
      </div>
      <div className="dashboard-hero-background rounded-[20px] p-8 flex gap-2 justify-between items-center">
        <div className="flex flex-col gap-2 justify-end">
          <h2 className="text-[32px] leading-9">Welcome to Virtu Network</h2>
          <p className="text-xs font-light max-w-[350px]">
            Making Mining Effortless, Cloud Computing Seamless: Virtu Network -
            Your Gateway to Simplicity in the Digital Realm.
          </p>
        </div>
        <div className="dashboard-btn-wrapper">
          <button
            className="text-[16px]"
            onClick={() => {
              setIsTopupOpen(true);
            }}
          >
            Topup
          </button>
        </div>
      </div>
      {/* <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
        <div className="bg-dark rounded-[30px] flex flex-col justify-center items-center gap-3 py-6">
          <p className="text-[#CFCFCF]">Nodes</p>
          <h2 className="xl:text-[64px] md:text-[48px] text-[64px] font-semibold text-[#C98BFF]">
            350
          </h2>
          <p className="text-[#CFCFCF]">on 5 blockchains</p>
        </div>
        <div className="bg-dark rounded-[30px] flex flex-col justify-center items-center gap-3 py-6">
          <p className="text-[#CFCFCF]">Miners</p>
          <h2 className="xl:text-[64px] md:text-[48px] text-[64px] font-semibold text-[#C98BFF]">
            000
          </h2>
          <p className="text-[#CFCFCF]">on 0 blockchains</p>
        </div>
        <div className="bg-dark rounded-[30px] flex flex-col justify-center items-center gap-3 py-6">
          <p className="text-[#CFCFCF]">Treasury</p>
          <h2 className="xl:text-[64px] md:text-[48px] text-[64px] font-semibold text-[#C98BFF] blur-[6px]">
            200,700
          </h2>
          <p className="text-[#CFCFCF]">Total value locked</p>
        </div>
      </div> */}
      <div className="px-6 py-4 bg-dark h-[160px] rounded-[20px] flex justify-between items-center gap-2">
        <div className="sm:block ">
          <img src={Logo} alt="vector" width="80px"/>
        </div>
        <p className="text-[16px]">
          {" "}
          © {new Date()?.getFullYear()} Virtu Network{" "}
        </p>
      </div>
      <div className="absolute -bottom-6 left-1/2 transform -translate-x-1/2 w-[60%] h-6 bg-[#0F0A14] z-[-1] blue_shadow_db"></div>
      <TopupModal
        isOpen={isTopupOpen}
        onClose={() => {
          setIsTopupOpen(false);
        }}
      />
    </div>
  );
};

export default Dashboard;
