import React from 'react'
import lights from '../../assets/svgs/lights.svg'

const Miners = () => {
  return (
    <div className="flex flex-col justify-between h-full">
    <div className="flex-1 flex justify-center items-center page_padding">
      <div className="max-w-xl relative nodes_shadow rounded-[50px]">
        <div className="absolute top-[15%] left-[40%] transform -translate-x-1/2 -translate-y-1/2">
          <img src={lights} alt="lights" />
        </div>
        <div className="w-full h-full bg-dark relative z-20 p-12 rounded-[50px]">
          <h2 className="text-[80px] font-semibold text-[#c5a8de] text-center">
            Virtu Miners
          </h2>
          <p className="text-xl text-center">
            Virtu Networks thanks you for your patience; Miners are getting
            implemented - launch soon
          </p>
        </div>
      </div>
    </div>
    <div className="h-[150px] flex justify-center items-center bg-dark">
      <p className="font-medium">© 2024 Virtu Network. All rights reserved.</p>
    </div>
  </div>
  )
}

export default Miners